const { PLAN_TYPES, PLAN_HANDLES } = require("../constants")

const parentPlans = [
    {
        title: "Forfait Découverte",
        subtitle: "Pour parents et enfants",
        color: "yellow-400",
        icon: "discover",
        handle: PLAN_HANDLES.PARENT_STANDARD,
        braintreePlans: {
            monthly: PLAN_TYPES.PARENT_STANDARD_MONTHLY,
            annual: PLAN_TYPES.PARENT_STANDARD_ANNUAL
        },
        modes: ["monthly", "annual"],
        list: [
            "2 Enfants",
            "Tous les jeux disponibles en accès illimité",
            "Création de 2 communautés"
        ],
        notYetList: [
            "Accès illimité aux produits du magasin"
        ],
        prices: {
            monthly: 10,
            annual: 50
        }
    },
    {
        title: "Forfait Progression",
        subtitle: "Pour parents et enfants",
        color: "blue-400",
        icon: "progress",
        handle: PLAN_HANDLES.PARENT_PREMIUM,
        modes: ["monthly", "annual"],
        braintreePlans: {
            monthly: PLAN_TYPES.PARENT_PREMIUM_MONTHLY,
            annual: PLAN_TYPES.PARENT_PREMIUM_ANNUAL
        },
        list: [
            "5 Enfants",
            "Tous les jeux disponibles en accès illimité",
            "Création illimité de communautés",
            "Accès illimité aux produits du magasin"
        ],
        prices: {
            monthly: 15,
            annual: 80
        }
    }
]

const teacherPlans = [
    {
        title: "Forfait Professeur",
        subtitle: "Pour les professeurs et les classes",
        color: "red-500",
        icon: "prof",
        handle: PLAN_HANDLES.TEACHER_STANDARD,
        modes: ["annual"],
        braintreePlans: {
            monthly: null,
            annual: PLAN_TYPES.TEACHER_STANDARD_ANNUAL
        },
        list: [
            "28 Étudiants",
            "Tous les jeux disponibles en accès illimité",
            "Création de 5 communautés",
            "Accès illimité aux produits du magasin"
        ],
        prices: {
            monthly: null,
            annual: 50
        }
    },
    {
        title: "Forfait sur demande",
        subtitle: "Pour les professeurs et les classes",
        color: "yellow-400",
        icon: "demand",
        handle: PLAN_HANDLES.TEACHER_ON_DEMAND,
        modes: ["annual"],
        braintreePlans: {
            monthly: null,
            annual: PLAN_TYPES.TEACHER_ON_DEMAND_ANNUAL
        },
        list: [
            "Nombre d'étudiants illimité",
            "Tous les jeux disponibles en accès illimité",
            "Création illimité de communautés",
            "Accès illimité aux produits du magasin",
            "Dictée généré par l'IA"
        ],
        notYetList: [
            "Exercises généré par l'IA (à venir)"
        ],
        prices: {
            monthly: null,
            annual: 90
        }
    }
]


module.exports = {
    teacherPlans,
    parentPlans
}
