<template>
    <div class="w-full">

        <loading-container :is-loading="loading">
            <div class="w-full" v-if="currentStep === 1">
                <h2 class="text-center mb-6">
                    {{ $t(`auth.parent`) }}
                </h2>

                <register-user-form @created="onCreatedUser" role="parent" />
            </div>

            <div class="w-full" v-if="currentStep === 2">
                <h2 class="text-center mb-12">
                    {{ $t("auth.choosePlan") }}
                </h2>

                <tabbed-buttons
                    @click="currentMode = $event"
                    :value="currentMode"
                    :label-key="(mode) => $t(`global.plans.modes.${mode}`)"
                    :value-key="null"
                    :options="['monthly', 'annual']" />

                <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <plan-type-card v-for="(plan) in plans"
                                    :key="plan.handle"
                                    :plan="plan"
                                    :current-mode="currentMode"
                                    @clicked="onPlanSelected"
                                    :selected="selectedPlanHandle === plan.handle"
                    />
                </div>
                <p
                    class="error"
                    v-if="errors.plan">
                    {{ errors.plan }}
                </p>

                <h2 class="text-center mb-3 mt-24">
                    {{ $t("modal.paymentMethod.title") }}
                </h2>

                <add-payment-method btn-class="min-w-32" class="w-full"
                                    @changed="onChangedPaymentMethod" />
            </div>
            <div class="w-full" v-if="currentStep === 3">
                <h2 class="text-center mb-3">
                    {{ $t("auth.createChildrens") }}
                </h2>
                <div class="flex flex-col justify-center items-center">
                    <div v-for="(student, idx) in students" :key="idx">
                        <div class="form flex flex-row justify-center items-center gap-4">
                            <div class="c__input w-full" v-for="fieldName in ['firstName', 'lastName']"
                                 :key="fieldName">
                                <label
                                    :for="fieldName"
                                    class="">
                                    {{ $t("form." + fieldName) }}
                                </label>

                                <input
                                    v-model="student[fieldName]"
                                    type="text"
                                    required
                                    :name="fieldName" />
                            </div>
                            <div class="c__input w-full">
                                <label>
                                    {{ $t("form.grade") }}
                                </label>
                                <div class="c__select">
                                    <select
                                        v-model.number="student.grade"
                                        name="grade"
                                        class="form-select">
                                        <option
                                            value=""
                                            selected>
                                            {{ $t("global.select") }}
                                        </option>
                                        <option
                                            v-for="grade in Object.values(GRADES)"
                                            :key="grade"
                                            :value="grade">
                                            {{ grade }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <SmallCircleButton
                                class="mt-6"
                                @click.prevent="removeStudent(idx)"
                                icon="trash" />
                        </div>
                        <p
                            class="error"
                            v-if="errors[`students.${idx}.firstName`]">
                            {{ errors[`students.${idx}.firstName`] }}
                        </p>
                        <p
                            class="error"
                            v-if="errors[`students.${idx}.lastName`]">
                            {{ errors[`students.${idx}.lastName`] }}
                        </p>
                        <p
                            class="error"
                            v-if="errors[`students.${idx}.grade`]">
                            {{ errors[`students.${idx}.grade`] }}
                        </p>
                    </div>
                    <div class="w-full flex flex-row justify-end items-center pt-2">
                        <CustomButton size="small" @click.prevent="addStudent">
                            {{ $t("students.addChildren") }}
                        </CustomButton>
                    </div>

                    <CustomButton
                        class="min-w-32 mt-6"
                        @click.prevent="onCreateStudents">
                        {{ $t("form.btnFinish") }}
                    </CustomButton>
                </div>


            </div>

            <p
                class="error"
                v-if="errors.global">
                {{ errors.global }}
            </p>

            <div v-if="[3].includes(currentStep)">
                <CustomButton
                    class="mt-6"
                    version="text"
                    @click.prevent="skipStep">
                    {{ $t("global.skipForNow") }}
                </CustomButton>
            </div>


            <CustomButton
                v-if="currentStep === 1"
                class="mt-6"
                version="text"
                @click.prevent="goBack">
                {{ $t("global.goBack") }}
            </CustomButton>
        </loading-container>
    </div>
</template>

<script>
import RegisterUserForm from "views/auth/register/RegisterUserForm"
import CustomButton from "components/globals/CustomButton"
import AddPaymentMethod from "components/payment/AddPaymentMethod.vue"
import Icons from "components/globals/Icons.vue"
import * as types from "store/types"
import { GRADES, PLAN_HANDLES, PLAN_TYPES } from "@globals/constants"
import cloneDeep from "lodash-es/cloneDeep"
import SmallCircleButton from "components/globals/SmallCircleButton.vue"
import { tryAsyncFunc } from "@globals/helpers/async"
import ajax from "config/ajax"
import PlanTypeCard from "components/cards/PlanTypeCard.vue"
import { parentPlans } from "@globals/data/plans"
import LoadingContainer from "components/LoadingContainer.vue"
import TabbedButtons from "components/globals/TabbedButtons.vue"

const STUDENT_INIT_DATA = {
    firstName: "",
    lastName: "",
    username: "",
    password: "",
    gender: "",
    lang: "",
    country: "",
    region: "",
    learningLang: "",
    grade: 1,
    difficulty: 1,
    classroomId: null
}

export default {
    name: "RegisterInstructionsParent",
    components: {
        TabbedButtons,
        LoadingContainer,
        PlanTypeCard,
        SmallCircleButton,
        Icons,
        AddPaymentMethod,
        CustomButton,
        RegisterUserForm
    },
    data() {
        return {
            GRADES,
            loading: false,
            selectedPlanHandle: null,
            selectedPlanType: null,
            currentMode: "monthly",
            errors: {},
            students: [],
            currentStep: 1,
            currentUser: null,

            plans: parentPlans
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        },
        onPlanSelected(planHandle) {
            this.selectedPlanHandle = planHandle
            this.selectedPlanType = null

            //auto select plan type
            switch (planHandle) {
                case PLAN_HANDLES.PARENT_STANDARD:
                    switch (this.currentMode) {
                        case "monthly":
                            this.selectedPlanType = PLAN_TYPES.PARENT_STANDARD_MONTHLY
                            break
                        case "annual":
                            this.selectedPlanType = PLAN_TYPES.PARENT_STANDARD_ANNUAL
                            break
                    }
                    break
                case PLAN_HANDLES.PARENT_PREMIUM:
                    switch (this.currentMode) {
                        case "monthly":
                            this.selectedPlanType = PLAN_TYPES.PARENT_PREMIUM_MONTHLY
                            break
                        case "annual":
                            this.selectedPlanType = PLAN_TYPES.PARENT_PREMIUM_ANNUAL
                            break
                    }
                    break
                default:
                    console.error("No plan handle found", planHandle)
                    break
            }
        },
        onCreatedUser(user) {
            this.currentUser = user
            this.nextStep()
        },
        async onCreateStudents() {
            this.errors = {}

            this.students.forEach((student, idx) => {
                if (!student.firstName) {
                    this.errors[`students.${idx}.firstName`] = this.$t("validationErrors.firstName")
                }
                if (!student.lastName) {
                    this.errors[`students.${idx}.lastName`] = this.$t("validationErrors.lastName")
                }
                if (!student.grade) {
                    this.errors[`students.${idx}.grade`] = this.$t("validationErrors.grade")
                }
            })

            if (Object.keys(this.errors).length > 0) {
                return
            }

            this.loading = true
            this.errors = {}
            for (let student of this.students) {
                try {
                    await this.$store.dispatch(types.USER_DATA.CREATE_STUDENT, student)
                } catch (e) {
                    console.error(e)
                }
            }
            this.loading = false
            this.nextStep()
        },
        addStudent() {
            let newStudent = cloneDeep(STUDENT_INIT_DATA)

            newStudent.lang = this.$store.state.auth.user.lang //from parent
            newStudent.country = this.$store.state.auth.user.country
            newStudent.region = this.$store.state.auth.user.region


            if (this.$store.state.auth.user.teachingLangs.length > 0) {
                newStudent.learningLang = this.$store.state.auth.user.teachingLangs[0]
            }

            this.students.push(newStudent)
        },
        removeStudent(idx) {
            this.students.splice(idx, 1)
        },
        async onChangedPaymentMethod(paymentMethod) {

            this.errors = {}

            if (!this.selectedPlanType) {
                this.errors.plan = this.$t("validationErrors.plan")
                return
            }

            this.loading = true
            //create plan
            const [res, err] = await tryAsyncFunc(ajax.post(`/user/me/subscription/create`, {
                planType: this.selectedPlanType
            }))

            if (err) {
                this.loading = false
                this.errors = err.errors || { plan: this.$t("validationErrors.plan") }
                return
            }

            await this.$store.dispatch(types.GET_USER)//make sure we refresh user data with license, etc

            this.loading = false

            this.addStudent()//add 1 first for students
            this.nextStep()
        },
        skipStep() {
            this.nextStep()
        },
        prevStep() {
            this.errors = {}
            if (this.currentStep === 0) return
            this.currentStep--
        },
        nextStep() {
            this.errors = {}

            //finish, go to dashboard
            if (this.currentStep === 3) {
                this.$router.push("/user/students")//will land into view of students
                return
            }

            this.currentStep++
        }
    }
}
</script>
